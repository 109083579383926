import React from 'react';
import Layout from 'src/components/layouts/BasicLayout';
import SEO from 'src/components/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDungeon, faSkull } from '@fortawesome/pro-solid-svg-icons';

export default function NotFoundPage(): JSX.Element {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex flex-col items-center text-gray-900 sm:flex-row max-w-lg mx-auto p-3">
        <div className="text-6xl text-gray-700 sm:pr-5">
          <FontAwesomeIcon icon={faDungeon} size="2x" />
        </div>
        <div>
          <h1 className="text-4xl font-bold size-tracking-wider">
            404 - DEAD END
          </h1>
          <p>
            Uh uh, looks like this is a dead end. You&apos;ll have to turn around and
            fight those skeletons!
          </p>
          <div className="mt-4">
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
            <FontAwesomeIcon className="mr-2" size="lg" icon={faSkull} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
